/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';

// Lib UI components

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';

import ContactForm from '../page-components/Index/ContactForm';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function Contact() {
  /* ********************************** HOOKS ********************************* */

  // Localization

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Contact" />
      <ContactForm />
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["Contact", "Common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Contact;
