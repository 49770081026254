/* eslint-disable jsx-a11y/label-has-associated-control */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import icon from '../../../images/index/Contact/icon.svg';
import localisation from '../../../images/index/Contact/localisation.svg';
import email1 from '../../../images/index/Contact/email.svg';
import phone1 from '../../../images/index/Contact/phone.svg';
import FormButton from '../../../shared/UIKit/FormButton';
// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Contact() {
  const { t } = useTranslation();
  const { title, description, adresse, email, phone, inputs, button } = t(
    'contact',
    {
      returnObjects: true,
    },
  );

  const InputsMap = inputs?.map(
    ({
      classN,
      classT,
      type,
      autoComplete,
      arialabelledby,
      id,
      name,
      placeholder,
      htmlFor,
    }) => (
      <div key={id} className={`input-contain ${classT}`}>
        <input
          className={classN}
          type={type || 'text'}
          autoComplete={autoComplete}
          aria-labelledby={arialabelledby}
          name={htmlFor}
          required
        />

        <label className="placeholder-text" htmlFor={htmlFor} id={name}>
          <div className="text">{placeholder}</div>
        </label>
      </div>
    ),
  );
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="form" fluid>
      <Row justify="center">
        <img className="form-img" src={icon} alt="icon" />

        <Col xxl={5} xl={5} md={12} sm={12} className="form-information">
          <h1 className="form-information-title">{title}</h1>
          <p className="form-information-description">{description}</p>
          <div className="form-information-contact">
            <img
              className="form-information-img"
              src={localisation}
              alt="localisation"
            />

            {adresse}
          </div>
          <div className="form-information-contact">
            <img className="form-information-img" src={email1} alt="email" />
            {email}
          </div>
          <div className="form-information-contact">
            <img className="form-information-img" src={phone1} alt="phone" />
            {phone}
          </div>
        </Col>
        <Col xxl={6} xl={6} md={12} sm={12}>
          <form
            action="https://formspree.io/f/mjvjrkpe"
            id="form"
            method="POST"
          >
            {InputsMap}
            <div className="input-contain">
              <textarea
                className="inputsd"
                autoComplete="off"
                aria-labelledby="placeholder-fname"
                name="message"
                required
              />
              <label
                className="placeholder-text"
                htmlFor="message"
                id="message"
              >
                <div className="text">Message</div>
              </label>
            </div>
            <div className="btn-contact">
              <FormButton text={button} />
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
